import React, { ReactNode } from 'react'

// JSON
import advantagesOfLoans from './intercel-steps.json'

// Components
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

// Icons
import Smartphones from 'inter-frontend-svgs/lib/orangeds/MD/smartphones'
import Search from 'inter-frontend-svgs/lib/orangeds/MD/search'
import Shipping from 'inter-frontend-svgs/lib/orangeds/MD/shipping'
import DepositByBoleto from 'inter-frontend-svgs/lib/orangeds/MD/deposit-by-boleto'
import CheckCircle from 'inter-frontend-svgs/lib/orangeds/MD/check-circle'

// Styles
import { Card } from './style'
import { orange } from 'src/styles/colors'

type CardProps = {
  icon: string;
  step: string;
  description: string;
}

interface IIconsList {
  [index: string]: ReactNode;
}

const AindaNaoEClienteInterCel = () => {
  const icons: IIconsList = {
    smartphones: <Smartphones color={orange.extra} width={42} height={42} />,
    search: <Search color={orange.extra} width={42} height={42} />,
    shipping: <Shipping color={orange.extra} width={42} height={42} />,
    depositByBoleto: <DepositByBoleto color={orange.extra} width={42} height={42} />,
    checkCircle: <CheckCircle color={orange.extra} width={42} height={42} />,
  }

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-5 col-xl-4'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 text-grayscale--500 fw-600 mb-3 mb-md-4'>Ainda não é cliente <span className='d-block d-md-inline'>Inter Cel?</span></h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--500 pt-md-2'>Contratando um plano da Inter Cel no Super App entre os dias 19/09 e 30/09, você também poderá concorrer a 7 Iphones 12. Para participar, é simples:</p>
          </div>
          <div className='col-12 col-md-7'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 30 }}
              md={{ items: 1, partialVisibilityGutter: 90 }}
              lg={{ items: 1, partialVisibilityGutter: 232 }}
              xl={{ items: 2, partialVisibilityGutter: 18 }}
            >
              {
                advantagesOfLoans.map((item: CardProps) => (
                  <Card key={item.icon}>
                    {icons[item.icon]}
                    <h3 className='fs-16 lh-20 text-grayscale--500 fw-600 mt-4 mb-0' dangerouslySetInnerHTML={{ __html: item.step }} />
                    <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--500 fw-400 mt-2 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                  </Card>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AindaNaoEClienteInterCel
