import React from 'react'

import MonthlyQuatroGB from './Plans/MonthlyPlans/_MonthlyQuatroGB'
import MonthlySeisGB from './Plans/MonthlyPlans/_MonthlySeisGB'
import MonthlyOitoGB from './Plans/MonthlyPlans/_MonthlyOitoGB'
import MonthlyTrezeGB from './Plans/MonthlyPlans/_MonthlyTrezeGB'
import MonthlyDezGB from './Plans/MonthlyPlans/_MonthlyDezGB'

import { ContentHeader } from './style'

const EscolhaPlano = () => {
  return (
    <ContentHeader id='planos-intercel' className='pb-4 pb-md-5 d-flex justify-content-between'>
      <div className='container-fluid px-4 px-md-5'>
        <div className='row align-items-center align-items-md-end justify-content-between justify-content-lg-start'>
          <div className='col-12 col-md-6 col-lg-4 col-xl text-center pt-md-5 mt-md-4'>
            <MonthlyDezGB />
          </div>
          <div className='col-12 col-md-6 col-lg-4 col-xl text-center pt-md-5 mt-md-4'>
            <MonthlyQuatroGB />
          </div>
          <div className='col-12 col-md-6 col-lg-4 col-xl text-center pt-md-5 mt-md-4'>
            <MonthlySeisGB />
          </div>
          <div className=' col-12 col-md-6 col-lg-4 col-xl text-center pt-md-5 mt-md-4'>
            <MonthlyOitoGB />
          </div>
          <div className='col-12 col-md-6 col-lg-4 col-xl text-center pt-md-5 mt-md-4'>
            <MonthlyTrezeGB />
          </div>
        </div>
        <div className='col-12 text-center mt-5 px-0'>
          <span className='lh-17 fs-14 text-grayscale--500 fw-400 d-block'>*O bônus de portabilidade não é válido para chips da Vivo e de suas operadoras móveis virtuais.</span>
        </div>
      </div>
    </ContentHeader>
  )
}

export default EscolhaPlano
