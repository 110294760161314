import React from 'react'

// components
import Layout from 'src/layouts/BaseLayout'

import {
  Hero,
  AindaNaoEClienteInterCel,
  ConhecaOsPlanos,
  EscolhaPlano,
  AdquiriuSeuChip,
  QuerSaberMaisSobreIntercel,
} from './sections/_index'

// assets
import pageContext from './pageContext.json'

// styles
import { Wrapper } from './style'

const Intercel = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <AindaNaoEClienteInterCel />
        <ConhecaOsPlanos />
        <EscolhaPlano />
        <AdquiriuSeuChip />
        <QuerSaberMaisSobreIntercel />
      </Layout>
    </Wrapper>
  )
}

export default Intercel
