import React from 'react'
import Img from 'gatsby-image'

import SectionComponent from 'src/components/SectionComponent'

import { TheBestDiv } from './style'

import pageQuery from '../../pageQuery'

const ConhecaOsPlanos = () => {
  const data = pageQuery()

  return (
    <SectionComponent
      id='conheca-os-planos'
      SectionStyles='py-5 bg-orange--extra'
      minHeight={{ sm: 'auto' }}
    >
      <div className='col-12 col-md-6 col-xl-5'>
        <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--100 mb-4 mb-md-0'>Conheça os planos Inter Cel e contrate agora!</h2>
      </div>
      <div className='col-12 col-md-6'>
        <div className='d-flex'>
          <TheBestDiv className='d-none d-md-block pr-4'>
            <Img fluid={data.intercelAniversarioBestInTest.fluid} alt='Best in Test Logo' />
          </TheBestDiv>
          <div>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--100 mb-3 mb-md-4'>
              Garanta bônus de internet e navegue no Super App do Inter e WhatsApp* sem gastar seus dados. E mais: tenha ligações ilimitadas e bônus adicionais em todos os planos! E tudo isso sem fidelidade. Vem ser Inter Cel!
            </p>
            <p className='d-none d-md-block fs-10 lh-12 fs-lg-12 lg-lg-16 fw-400 text-white'>
              * WhatsApp ilimitado para enviar e receber mensagens, áudios, vídeos e fotos.
            </p>
          </div>
          <TheBestDiv className='d-md-none pl-4'>
            <Img fluid={data.intercelAniversarioBestInTest.fluid} alt='Best in Test Logo' />
          </TheBestDiv>
        </div>
        <p className='d-md-none fs-10 lh-12 fs-md-12 lg-md-16 fw-400 text-white'>
          * WhatsApp ilimitado para enviar e receber mensagens, áudios, vídeos e fotos.
        </p>
      </div>
    </SectionComponent>
  )
}

export default ConhecaOsPlanos
