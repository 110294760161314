import React, { useState } from 'react'

import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import useDomReady from 'src/hooks/window/useDomReady'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import WazeIcon from '../../../../assets/images/Waze.png'
import MoovitIcon from '../../../../assets/images/Moovit.png'
import HirePlanApp from '../../../../../pra-voce/plano-de-celular/assets/images/qr-code-inter-cel.jpg'

import {
  iconFacebook,
  iconFacebookMessenger,
  iconInstagram,
  iconPinterest,
  iconTikTok,
  iconTinder,
  iconTwitter,
} from '../../assets/icons/_index'

import { Card, CardWrapper, CardHeader, CardBody, CardFooter } from '../../style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const MonthlyDezGB = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()

  const ModalInterCelRight = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-block fs-24 lh-30"><span class="d-block">Acesse o <span class="text-orange--extra">Super App,</span></span>e contrate o seu plano agora!</span>'
        subtitle='<span class="d-block fs-18 lh-22">Os planos da Inter cel são exclusivos para clientes Inter. Você pode adquiri-los dentro<span class="d-lg-block"> do nosso Super App. Acesse, escolha seu plano e aproveite!</span></span>'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para
        a loja de aplicativos. Aí é só baixar o app do Inter.'
        qrCode={HirePlanApp}
      />
    </Modal>
  )

  return (
    <Card className=''>
      {ModalInterCelRight}
      <CardWrapper bestSeller>
        <span className='text-white fw-600 fs-24 lh-30'>
          REDES SOCIAIS
        </span>
        <CardHeader className=''>
          <span className='d-block fw-600 fs-40 lh-50 text-grayscale--500 mb-1 sora'>10 GB</span>
          <span className='d-block fs-16 lh-20 text-grayscale--500 mb-2 sora'>4GB +2GB de bônus + <span className='d-block'>4GB Redes Sociais</span></span>
          <span className='d-block fw-700 fs-14 lh-17 text-grayscale--500 mb-2'>Apps inclusos:</span>
          <div className='icons-wrapper'>
            <OrangeIcon size='MD' color='#ff7a00' icon='notification-center' />
            <OrangeIcon size='MD' color='#25D366' icon='whatsapp' className='ml-1' />
            <img src={WazeIcon} alt='waze' className='ml-1' />
            <img src={MoovitIcon} alt='moovit' />
          </div>
          <span className='d-block fw-700 fs-14 lh-17 text-grayscale--500 mb-2 mt-3'>Redes sociais</span>
          <div className='icons-wrapper'>
            <img src={iconInstagram} alt='instagram icon' className='ml-1' />
            <img src={iconTikTok} alt='Tik Tok icon' className='ml-1' />
            <img src={iconFacebook} alt='Facebook icon' className='ml-1' />
            <img src={iconTinder} alt='Tinder icon' className='ml-1' />
            <img src={iconFacebookMessenger} alt='Facebook Messenger icon' className='ml-1' />
            <img src={iconTwitter} alt='Twitter icon' className='ml-1' />
            <img src={iconPinterest} alt='Pinterest icon' />
          </div>
        </CardHeader>
        <CardBody className=''>
          <div className='body-items mb-4'>
            <OrangeIcon size='MD' color='#00AA4F' icon='check' />
            <span className='fw-700 fs-14 lh-17 text-grayscale--500'>
              Ligações ilimitadas
              <span className='d-block fs-12 lh-15 fw-400 mt-1'>
                Fixo, celular e DDDs usando o 15
              </span>
            </span>
          </div>
          <div className='body-items mb-4'>
            <OrangeIcon size='MD' color='#00AA4F' icon='check' />
            <span className='fw-700 fs-14 lh-17 text-grayscale--500'>
              1GB de bônus de recarga
              <span className='d-block fs-12 lh-15 fw-400 mt-1'>
                Válidos por 7 dias
              </span>
            </span>
          </div>
          <div className='body-items  mb-4'>
            <OrangeIcon size='MD' color='#00AA4F' icon='check' />
            <span className='fw-700 fs-14 lh-17 text-grayscale--500'>
              4GB de bônus mensais na portabilidade*
              <span className='d-block fs-12 lh-15 fw-400 mt-1'>
                Válido por 12 meses
              </span>
            </span>
          </div>
          <div className='body-items'>
            <OrangeIcon size='MD' color='#00AA4F' icon='check' />
            <span className='fw-700 fs-14 lh-17 text-grayscale--500'>
              Plano válido por 30 dias
              <span className='d-block fs-12 lh-15 fw-400 mt-1'>
                Ative a renovação automática
              </span>
            </span>
          </div>
        </CardBody>
        <CardFooter className=''>
          <div className='d-flex justify-content-center align-items-center mb-2'>
            <span className='fw-600 fs-40 lh-50 text-grayscale--500 d-block mb-0 sora'>R$45</span>
            <div className='text-left ml-1'>
              <div className='fw-400 fs-16 lh-20 text-grayscale--500'>/mês</div>
            </div>
          </div>
          <span className='success fw-700 fs-14 lh-17 d-block mb-3'>
            <OrangeIcon size='MD' color='#00AA4F' icon='cashback' />
            R$1,80
            <span className='fw-400'> de cashback</span>
          </span>
          <div className='d-md-none'>
            <a
              href='bancointer://intercel?landingPage=true'
              className='btn btn--lg btn-orange--extra fs-14 lh-17 fw-700 rounded-2 text-none'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  section_name: '10 GB',
                  element_action: 'click button',
                  element_name: 'Contratar pelo app',
                  redirect_url: 'bancointer://intercel?landingPage=true',
                })
              }}
            >
              Contratar pelo app
            </a>
          </div>
          <button
            onClick={() => {
              setIsOpen(true)
              sendDatalayerEvent({
                section: 'dobra_04',
                section_name: '10 GB',
                element_action: 'click button',
                element_name: 'Contratar pelo app',
              })
            }}
            className='btn btn--lg btn-orange--extra fs-14 lh-17 fw-700 rounded-2 d-none d-md-block text-none'
          >
            Contratar pelo app
          </button>
        </CardFooter>
      </CardWrapper>
    </Card>
  )
}

export default MonthlyDezGB
