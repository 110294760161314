import React from 'react'
import SectionComponent from 'src/components/SectionComponent'

const AdquiriuSeuChip = () => {
  return (
    <SectionComponent
      id='formulario-adquiriu-seu-chip'
      SectionStyles='py-5 bg-orange--extra'
      RowStyles='justify-content-md-center justify-content-lg-start'
      minHeight={{ sm: 'auto' }}
    >
      <div className='col-12 text-center py-4'>
        <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-white mb-4'>Prazo de inscrição encerrado!</h2>
        <h3 className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-20 lh-xl-25 fw-400 text-white pt-3 mb-4'>O resultado do sorteio será divulgado por e-mail, no dia 20/10.</h3>
      </div>
    </SectionComponent>
  )
}

export default AdquiriuSeuChip
