import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Card = styled.div`
  height:258px;
  border-radius:8px;
  background: ${grayscale[100]};
  padding: 26px 24px 64px;
  margin-right: 12px;
  border: 1px solid ${grayscale[200]};

  @media ${device.tablet} {
    height: 242px;
    padding: 26px 19px 64px;
  }
`
