import React from 'react'
import { Link } from 'gatsby'

import SectionComponent from 'src/components/SectionComponent'

// Style
import { orange } from 'src/styles/colors'

// Icons
import InterResearch from 'inter-frontend-svgs/lib/orangeds/MD/inter-research'
import Smartphones from 'inter-frontend-svgs/lib/orangeds/MD/smartphones'
import ArrowRight from 'inter-frontend-svgs/lib/orangeds/MD/arrow-right'

import { Card } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const QuerSaberMaisSobreIntercel = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='quer-saber-mais-sobre-intercel'
      SectionStyles='py-5'
      minHeight={{ sm: 'auto' }}
    >
      <div className='col-12 col-md-6 col-lg-4 col-xl-12 mb-xl-5'>
        <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-xl-center text-grayscale--500'>Quer saber mais sobre a Inter Cel?</h2>
      </div>
      <div className='col-12 col-md-6 col-lg-8 col-xl-12'>
        <div className='d-md-none'>
          <Card>
            <a
              href='https://marketing.bancointer.com.br/arquivos/pdf/intercel/regulamento-promocao-banco-inter-lf-autorizado-010224962022-74.pdf'
              target='_blank'
              rel='noreferrer'
              className='anchor-block d-flex justify-content-between align-items-center'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  section_name: 'Quer saber mais sobre a Inter Cel?',
                  element_action: 'click button',
                  element_name: 'Regulamento da promoção',
                  redirect_url: 'https://marketing.bancointer.com.br/arquivos/pdf/intercel/regulamento-promocao-banco-inter-lf-autorizado-010224962022-74.pdf',
                })
              }}
            >
              <h4 className='fs-12 lh-15 fs-md-16 lh-lg-20 fw-700 text-grayscale--500 mb-0'>Regulamento da promoção</h4>
              <InterResearch color={orange.extra} width={24} height={24} />
            </a>
          </Card>
          <Card>
            <a
              href='https://inter.co/intercel/'
              target='_blank'
              rel='noreferrer'
              className='anchor-block d-flex justify-content-between align-items-center'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  section_name: 'Quer saber mais sobre a Inter Cel?',
                  element_action: 'click button',
                  element_name: 'Entre no nosso site e conheça todas as vantagens!',
                  redirect_url: 'https://inter.co/intercel/',
                })
              }}
            >
              <h4 className='fs-12 lh-15 fs-md-16 lh-lg-20 fw-700 text-grayscale--500 mb-0'>Entre no nosso site e conheça todas as vantagens!</h4>
              <Smartphones color={orange.extra} width={24} height={24} />
            </a>
          </Card>
        </div>
        <div className='d-none d-md-block d-xl-flex justify-content-lg-between'>
          <Card className='mb-4'>
            <a
              href='https://marketing.bancointer.com.br/arquivos/pdf/intercel/regulamento-promocao-banco-inter-lf-autorizado-010224962022-74.pdf'
              target='_blank'
              rel='noreferrer'
              className='anchor-block d-flex justify-content-lg-between'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  section_name: 'Quer saber mais sobre a Inter Cel?',
                  element_action: 'click button',
                  element_name: 'Regulamento da promoção',
                  redirect_url: 'https://marketing.bancointer.com.br/arquivos/pdf/intercel/regulamento-promocao-banco-inter-lf-autorizado-010224962022-74.pdf',
                })
              }}
            >
              <div className='mr-2 d-lg-none'>
                <InterResearch color={orange.extra} width={24} height={24} />
              </div>
              <div className='d-lg-flex align-items-lg-center'>
                <div className='mr-2 d-none d-lg-block'>
                  <InterResearch color={orange.extra} width={24} height={24} />
                </div>
                <h4 className='fs-12 lh-15 fs-md-16 lh-md-20 fw-700 text-grayscale--500 mb-4 mb-lg-0'>Regulamento da promoção</h4>
                <div className='d-flex align-items-center align-items-lg-start d-lg-none'>
                  <a className='fs-14 lh-17 text-orange--extra pr-4'>Veja agora mesmo</a>
                  <ArrowRight color={orange.extra} width={24} height={24} />
                </div>
              </div>
              <div className='d-none d-lg-flex align-items-center'>
                <a className='fs-14 lh-17 text-orange--extra pr-4'>Veja agora mesmo</a>
                <ArrowRight color={orange.extra} width={24} height={24} />
              </div>
            </a>
          </Card>
          <Card>
            <a
              href='https://inter.co/intercel/'
              target='_blank'
              rel='noreferrer'
              className='anchor-block d-flex justify-content-lg-between'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  section_name: 'Quer saber mais sobre a Inter Cel?',
                  element_action: 'click button',
                  element_name: 'Entre no nosso site e conheça todas as vantagens!',
                  redirect_url: 'https://inter.co/intercel/',
                })
              }}
            >
              <div className='mr-2 mt-1 d-lg-none'>
                <Smartphones color={orange.extra} width={24} height={24} />
              </div>
              <div className='d-lg-flex align-items-lg-center'>
                <div className='mr-2 mt-1 mt-xl-0 d-none d-lg-block'>
                  <Smartphones color={orange.extra} width={24} height={24} />
                </div>
                <h4 className='fs-12 lh-15 fs-md-16 lh-md-20 fw-700 text-grayscale--500 mb-4 mb-lg-0'>Entre no nosso site e conheça <span className='d-lg-block'>todas as vantagens!</span></h4>
                <div className='d-flex align-items-center align-items-lg-start d-lg-none'>
                  <a className='fs-14 lh-17 text-orange--extra pr-4'>Fique por dentro</a>
                  <ArrowRight color={orange.extra} width={24} height={24} />
                </div>
              </div>
              <div className='d-none d-lg-flex align-items-center'>
                <a className='fs-14 lh-17 text-orange--extra pr-4'>Fique por dentro</a>
                <ArrowRight color={orange.extra} width={24} height={24} />
              </div>
            </a>
          </Card>
        </div>
      </div>
    </SectionComponent>
  )
}

export default QuerSaberMaisSobreIntercel
