import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Card = styled.div`
  .anchor-block {
    border-bottom: 1px solid ${grayscale[200]};
    padding: 15px 0;
    @media ${device.tablet} {
      border: 1px solid ${grayscale[200]};
      padding: 24px;
      border-radius: 16px;
      height: 140px;
    }
    @media ${device.desktopLG} {
      height: 80px;
    }
    @media ${device.desktopXL} {
      width: 550px;
    }
  }
`
