import styled, { css } from 'styled-components'

// Background Image
import mainBgIntercelUmAnoMobile from '../../assets/images/background-intercel-um-ano-mobile.png'
import mainBgIntercelUmAnoTablet from '../../assets/images/background-intercel-um-ano-tablet.png'
import mainBgIntercelUmAnoLG from '../../assets/images/background-intercel-um-ano-lg.png'
import mainBgIntercelUmAnoXL from '../../assets/images/background-intercel-um-ano-xl.png'

// Background Position
import formBgIntercelUmAnoMobile from '../../assets/images/bg-forms-intercel-um-ano-mobile.png'
import formBgIntercelUmAnoTablet from '../../assets/images/bg-forms-intercel-um-ano-tablet.png'
import formBgIntercelUmAnoLG from '../../assets/images/bg-forms-intercel-um-ano-lg.png'
import formBgIntercelUmAnoXL from '../../assets/images/bg-forms-intercel-um-ano-xl.png'

import { device } from 'src/styles/breakpoints'

const MainBackground = css`
  height: 700px;
  background-image: url(${mainBgIntercelUmAnoMobile}), url(${formBgIntercelUmAnoMobile});
  background-repeat: no-repeat;
  background-position: top center, 100% 75%;

  @media ${device.tablet} {
    height: 447px;
    background-image: url(${mainBgIntercelUmAnoTablet}), url(${formBgIntercelUmAnoTablet});
    background-position: left center, 96% 98%;
  }

  @media ${device.desktopLG} {
    height: 493px;
    background-image: url(${mainBgIntercelUmAnoLG}), url(${formBgIntercelUmAnoLG});
    background-position: left center, 96% 98%;
  }

  @media ${device.desktopXL} {
    height: 580px;
    background-image: url(${mainBgIntercelUmAnoXL}), url(${formBgIntercelUmAnoXL});
    background-position: left center, 96% 98%;
  }
`

export const SectionConhecaOsPlanos = styled.section`
  ${MainBackground}
  a {
    font-size: 14px;
    font-weight: 700;
    height: 48px;
    border-radius: 8px !important;
    @media ${device.desktopLG} {
      min-width: 100%;
    }
  }

  .gatsby-image-wrapper {
    @media ${device.tablet} {
      width: 246px;
      height: 101px;
    }
    @media ${device.desktopLG} {
      width: 285px;
      height: 117px;
    }
    @media ${device.desktopXL} {
      width: 392px;
      height: 161px;
    }
  }
`
