import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      heroBannerUmAnoIntercel: imageSharp(fluid: { originalName: { regex: "/herobanner-aniversario-intercel/" }}) {
        fluid(maxWidth: 392, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      intercelAniversarioBestInTest: imageSharp(fluid: { originalName: { regex: "/intercel-aniversario-best-in-test/" }}) {
        fluid(maxWidth: 58, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
