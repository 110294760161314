import React from 'react'
import Img from 'gatsby-image'

import pageQuery from '../../pageQuery'
import { SectionConhecaOsPlanos } from './style'

const Hero = () => {
  const data = pageQuery()

  return (
    <SectionConhecaOsPlanos className='bg-orange--extra d-flex align-items-end align-items-md-center pb-5 pb-md-0'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5 offset-md-6 offset-xl-7'>
            <div className='mb-3'>
              <Img fluid={data.heroBannerUmAnoIntercel.fluid} alt='Mulher sorrindo segurando um oculos' />
            </div>
            <h1 className='fs-24 lh-30 fs-xl-32 lh-xl-40 fw-600 text-white mb-3'>Concorra a 7 iPhones 12</h1>
            <p className='fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-18 lh-xl-22 text-white mb-4'>
              A Inter Cel está completando 1 ano com a melhor rede móvel do Brasil. Para comemorar, vamos sortear 7 iPhones 12 de 128GB.
            </p>
            <p className='fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-18 lh-xl-22 text-white mb-4'>
              Inscrições só até 30/09. Válido apenas para quem é Inter Cel. Confira as regras e participe!
            </p>
          </div>
        </div>
      </div>
    </SectionConhecaOsPlanos>
  )
}

export default Hero
